import * as React from 'react'
import Menu from "../components/menu";

const SubCats = ({cats}) => {
  const catsProcessed = cats.map(({id, name, slug}) => {
    return {
      id,
      name,
      uri: `/products/${decodeURI(slug)}/`
    }
  })

  return (
    <Menu items={catsProcessed} tagStyle={true} />
  )
}

export default SubCats