import * as React from 'react'
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const Image = ({product}) => {
  const image = product.featuredImage ? getImage(product.featuredImage.node.localFile) : null

  if (image) {
    return (
      <GatsbyImage
        className="w-full mb-6"
        image={image}
        alt={product.title}
      />
    )
  }

  return null
}

export default Image