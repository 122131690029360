import * as React from 'react'
import {graphql} from 'gatsby'
import parse from 'html-react-parser'
import Layout from '../layouts/layout'
import Image from '../components/FeaturedImage'
import SubCats from '../components/subCats'

const IndexPage = ({data}) => {
  return (
    <Layout pageTitle={data.wpLatteproduct.title}>
      <div className="mb-24">
        <SubCats cats={data.wpLatteproduct.lattecats.nodes} />
      </div>
      <div className="prose mx-auto">
        <Image product={data.wpLatteproduct}/>
        {!!data.wpLatteproduct.content && (
          <section>{parse(data.wpLatteproduct.content.replace('http://hobbybooks.ntwrk.marketto.ru', ''))}</section>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpLatteproduct(id: {eq: $id}) {
      id
      title
      slug
      content
      lattecats {
        nodes {
          id
          name
          slug
        }
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

export default IndexPage
